<template>
  <div class="content-list-content admin_container_default">
    <div class="content-list-table cpx-4">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            URL管理
          </div>
        </div>
        <div class="row-input admin_center"></div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button class="button-create-content" v-on:click="createURL()">
            新規登録
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <Tables
        :items="listUrlData.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
        class="list-user-note"
        :loading="loadingTable"
      >
        <template v-slot:ID="{ index }">
          <td style="width: 5%">{{ index + listUrlData.from }}</td>
        </template>
        <template v-slot:short_url="{ item }">
          <td
            style="width: 30%; max-width: 300px; overflow-wrap: break-word"
            class="py-2"
          >
            <div class="text-truncate-mess">
              {{
                item.short_url && item.short_url.startsWith("/")
                  ? item.short_url.substring(1)
                  : item.short_url
              }}
            </div>
          </td>
        </template>
        <template v-slot:original_url="{ item }">
          <td
            style="width: 30%; max-width: 300px; overflow-wrap: break-word"
            class="py-2"
          >
            <div
              class="text-truncate-mess yuan_url"
              :data-id="item.id"
              v-bind="clips"
            >
              {{
                item.st_url_redirect
                  ? JSON.parse(item.st_url_redirect).url_no_condtion
                  : item.original_url
              }}
            </div>
          </td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <CButton
              class="mx-1 btn-other bg-0083cf"
              color="primary"
              square
              variant="outline"
              size="sm"
              v-on:click="copyUrl(item.short_url)"
            >
              URLコピー
            </CButton>
            <CButton
              class="mx-1 btn-other"
              color="primary"
              square
              variant="outline"
              size="sm"
              v-on:click="editUrl(item.id, index + listUrlData.from)"
            >
              編集
            </CButton>
            <CButton
              class="mx-1 btn-del"
              color="danger"
              square
              v-on:click="showConfirm(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-url
            >
              削除
            </CButton>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listUrlData.total"
      >
        <p class="font-weight-bold">
          {{ listUrlData.total }}件中{{ listUrlData.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listUrlData.total"
      >
        <CPagination
          v-if="listUrlData.total"
          :activePage.sync="page"
          :pages="listUrlData.last_page"
          align="center"
        />
      </div>
    </div>
    <!-- MODAL DELETE URL -->
    <div>
      <b-modal id="delete-modal-url" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            :disabled="isDisable"
            @click="removeUrl(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-url')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "URLManager",
  data() {
    return {
      fields: tableFields.URL,
      listLimitRecordPage: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      dataModal: "",
      name: "",
      email: "",
      created_from: "",
      created_to: "",
      updated_from: "",
      updated_to: "",
      page: 1,
      extConstant: this.$route.params.shopId
        ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
        : "_" + Constants.DOMAIN,
      shop_id: null,
      user_id: null,
      isDisable: false,
      copy_url: "",
      clips: "",
      listUrlData: [],
      disablePaigation: false,
      loadingTable: false,
    };
  },
  components: {
    Tables,
    // DatePick,
  },
  created() {
    this.shop_id =
      localStorage.getItem(Constants.ADMIN_USER_INFO + this.extConstant) &&
      JSON.parse(
        localStorage.getItem(Constants.ADMIN_USER_INFO + this.extConstant)
      ).shop_id;
    const formData = {
      page: this.page,
      data: {
        shop_id: this.shop_id,
        limit: Constants.LIMIT_URL,
      },
    };
    this.getListURL(formData);
    const { params } = this.$route;
    // this.shop_id = params.shopId;
    this.user_id = params.id;
  },
  computed: {
    ...mapGetters(["listUrl", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listUrl() {
      this.listUrlData = this.listUrl;
      // this.page = this.listUrlData.current_page;
      this.loadingTable = false;
      this.disablePaigation = false;
    },
    page() {
      const formData = {
        page: this.page,
        data: {
          shop_id: this.shop_id,
          limit: Constants.LIMIT_URL,
        },
      };
      this.disablePaigation = true;
      this.loadingTable = true;
      this.getListURL(formData);
    },
  },
  methods: {
    ...mapActions({ getListURL: "getListURL" }),
    ...mapActions({ deleteURL: "deleteURL" }),
    showConfirm(id) {
      this.dataModal = id;
    },
    clickPagination(page) {
      console.log("page", page);
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: Constants.LIMIT_URL,
        },
      };
      this.getListURL(formData);
      this.disablePaigation = true;
    },
    async removeUrl(id) {
      this.isDisable = true;
      const req = {
        id: id,
        shop_id: this.shop_id,
      };
      const dataReturn = await this.$store.dispatch("deleteURL", req);
      if (dataReturn.success) {
        this.isDisable = false;
        this.$nextTick(() => {
          this.$bvModal.hide("delete-modal-url");
          this.listUrlData.data = this.listUrlData.data.filter((item) => {
            return item.id != id;
          });
          this.listUrlData.total -= 1;
          const formData = {
            page: this.page,
            data: {
              shop_id: this.shop_id,
              limit: Constants.LIMIT_URL,
            },
          };
          this.getListURL(formData);
        });
      } else {
        this.isDisable = false;
      }
    },
    createURL() {
      localStorage.setItem(Constants.NO_URL, this.listUrlData.total + 1);
      this.$router.push({
        name: this.$route.params.shopId ? "CreateURL" : "CreateURL domain",
      });
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "DashboardAdminBox" });
      } else {
        this.$router.push({ name: "DashboardAdminBox domain" });
      }
    },
    editUrl(id, no) {
      localStorage.setItem(Constants.NO_URL, no);
      this.$router.push({
        name: this.$route.params.shopId ? "editURL" : "editURL domain",
        params: { id: id },
      });
    },
    copyUrl(short_url) {
      if (short_url && short_url.startsWith("/")) {
        short_url = short_url.substring(1);
      }
      const hostName = window.location.origin + "/";
      // if (!short_url.startsWith("/")) {
      //   short_url = "/" + short_url;
      // }
      var href = this.$route.params.shopId
        ? "/shop/" + this.$route.params.shopId + short_url
        : short_url;
      var copyText = hostName + href;
      navigator.clipboard.writeText(copyText);
      this.$store.commit("set", ["message", Constants.MESSAGE_COPY]);
      this.$store.commit("set", ["success", true]);
    },
  },
};
</script>

<style>
.disablePaigation {
  pointer-events: none;
}
</style>
